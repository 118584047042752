import React from 'react'

import java from '../images/training/java.png'
import sap from '../images/training/sap.png'
import salesforce from '../images/training/salesforce.png'
import dotnet from '../images/training/dotnet.png'
import bigdata from '../images/training/big-data.png'
import ba from '../images/training/ba.png'
import devops from '../images/training/devops.png'
import st from '../images/training/st.png'
import qa from '../images/training/qa.png'
import dm from '../images/training/dm.png'
function Training() {
  return (
    <div className='second-container'>


      <div className="container">

        
          <div className="col-lg-12 mb-5">
          <div className="row">
            {TrainingData.map((x,i)=>{


              return (

                <div className="col-lg-3">
                <div class="card caro-card">
              <div class="card-body">
              <img src={x.img} class="img-fluid mx-auto d-block mb-5" alt=""/>
                <h4>{x.name}</h4>
              </div>
              </div>
                </div>
              )
            })}
           
          </div>
        </div>
      </div>
    

    </div>
  )
}

export default Training


export const TrainingData = [

  {
    name: 'Java',
    img: java,
    desc: 'ANR consultants helps you to build and implement the Java technology that will bring the best results for your specific goals.'
  },
  {
    name: 'SAP',
    img: sap,
    desc: 'ANR consultants has the skillset and experience to assist with all aspects of your SAP application lifecycle.'
  },
  {
    name: 'Sales Force',
    img: salesforce,
    desc: 'ANR consultants delivers quality solutions quickly by starting with proven assets from thousand’s of past projects.'
  },
  {
    name: '.NET',
    img: dotnet,
    desc: 'ANR consultants are experts in design and implementation of .NET applications adding value to the products and clients.'
  },
  {
    name: 'Big Data',
    img: bigdata,
    desc: 'ANR consultants are experts in design and implementation of Big Data applications adding value to the products and clients.'
  },
  {
    name: 'Business Analyst',
    img: ba,
    desc: 'ANR consultants are experts in providing Business Analyst services for applications adding value to the products and clients.'
  },
  {
    name: 'DevOps',
    img: devops,
    desc: 'ANR consultants are experts in Devops services  by adding value to the products and clients.'
  },
  {
    name: 'Service Testing',
    img: st,
    desc: 'ANR consultants are experts in Service Testing  by adding value to the products and clients.'
  }
  ,
  {
    name: 'Quality Analyst',
    img: qa,
    desc: 'ANR consultants are experts in Quality Analyst  by adding value to the products and clients.'
  },
  {
    name: 'Data Technologies',
    img: dm,
    desc: 'ANR consultants Data warehousing consulting Services help clients to quickly obtain insights and support decision making.'
  }]