import React from 'react'

function DescMain({mainHead,title,para ,img,shortDesc}) {
  return (
    <>
            <div className="card mb-4">
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-4 text-center">
                        <img src={img} className='img-fluid mx-auto d-block' alt="" />
                            <h2 className='mt-5'>{title}</h2>
                        </div>
                        <div className="col-lg-8 text-start">
                         <p className='mainHead'>{mainHead}</p>

                        {para == null||undefined ? '': <p className='mainHead'>{para}</p>} 


                        </div>

                    </div>
                
                </div>
            </div>
    </>
  )
}

export default DescMain