import React from 'react'
import DescMain from '../components/DescCards/DescMain'
import DescSecondList from '../components/DescCards/DescSecondList'
import qa from '../images/training/qa.png'
function Qa() {

  return (
    <div className='second-container'>

      <div className="container">
        <DescMain mainHead={mainHead} para={para} img={qa} title={title} />
        <DescSecondList listObj={listObj} />



      </div>


    </div>
  )
}

export default Qa

const title = 'QUALITY ANALYST';

const mainHead = 'Quality is the result of intelligence effort. Do you know how an organization ensure its product quality to its client or customers? who analyze the qualtiy of the product? The answer is Quality Analyst.In modern times, quality analysts have become a leading job role in the software development industry,such is the importance of this role.'
const para = "What does the Quality Analyst do and what responsibilites does he have? The quality analyst is responsible for testing the products and functionality of the products. This testing is done to ensure that product meets the organization's standards and common practices. ANR CONSULTING has experts who  diagnose any loopholes that sacrifice the expectations that consumers have from the service by collaborating with  design, development and management teams and get project delivered efficiently. ANR CONSULTANTS are proficient in QA testing techniques that are used to interpret the negatives which effect  a product or service and they are trained to identify hardware flaw that increases manufacturing costs and many more and iron them out."
const listObj = {
  title: 'THE SERVICES AT ANR CONSULTING INCORPORATED:-',
  shortDesc: '',
  points: [
    'Identify defects in early stage of  the development cycle',
    'Develop and execute software test plans',
    'Identify and facilitate issue resolution with functional and technical groups',
    'Debugging and managing the existing bugs.',
    'performing automation testing. ',
    ' Developing and maintaining the test strategies, regression plans, cross-feature plans, and tests encompassing manual and automation.',
    'Test coverage improved to offer better quality',


  ]
}

