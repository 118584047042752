import React from 'react'
import {ImCheckmark2 } from 'react-icons/im'
function DescSecondList({listObj}) {

  

  return (
    <div className='card mb-2 p-3'>

        <div className="card-body">
            <h3 className='color-black'>{listObj.title}</h3>
                <p>{listObj.shortDesc}</p>
                {listObj.points.map((x,i)=>{

                    return(
                        <div className="d-flex align-items-start justify-space-between">
                        <div className="pt-3 mt-2">
                            <ImCheckmark2 className='rigthArrow' />
                        </div>
                        <div className="point p-2">
                            <div className='mt-3 ml-3'>
                                
                                <p className='text-mid mb-1'>
                                {x}
                                </p>
                            </div>
                        </div>
                    </div>

                    )
                 


                })}
            
        </div>
        
    </div>
  )
}

export default DescSecondList