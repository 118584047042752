import React from 'react'
import DescMain from '../components/DescCards/DescMain'
import DescSecondList from '../components/DescCards/DescSecondList'
import ba from '../images/training/ba.png'
function Ba() {

  return (
    <div className='second-container'>

               <div className="container">
               <DescMain mainHead={mainHead} para={para} img={ba} title={title}/>
                <DescSecondList listObj={listObj}/>
               </div>
        
         
    </div>
  )
}

export default Ba

const title='Business Analyst';

const mainHead='WHO IS A BUSINESS ANALYST?  Someone who does precision guesswork based on unreliable data provided by those of questionable knowledge.'
const para="Did you know why there was a need of business analyst? It is to  identify business areas that can be improved to increase efficiency and strengthen business processes, they can also help companies lower business risks, enhance business value as well as find ways to lower costs. In today's digital era there is foremost demand for a skilled business analyst as they draw out the needs that are not yet know for organization betterment. ANR CONSULTING here comes to your aid. we provide customized solutions to our client by understanding  the requirements, issues and needs of the client's short-term or long-term goal, by formulating plans to implement their recommendations and provide training to the staff to cope with the changes. We provide the most suitable solution for improving organization efficiency, performance in terms of IT development, organization structure, staff development and many more."


const listObj={
  title:'BUSINESS ANALYSIS SERVICES AT ANR CONSULTING INCORPORATE:-',
  shortDesc:'',
  points:[
    "Identifying and prioritizing the organization's functional and technical needs and requirements",
    'Using technoligies SQL and Excel to analyze large data and other technical knowledge required',
    'Planning enterprise architecture ',
    'Creating financial models to support business decisions',
    'Forecasting, budgeting, and performing both variance analysis and financial analysis',
    'Updation on latest technologies introduced',
    
  ]
}