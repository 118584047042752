
import React from 'react'
import DescMain from '../components/DescCards/DescMain'
import DescSecondList from '../components/DescCards/DescSecondList'
import devops from '../images/training/devops.png'
function Devops() {
  return (
  <div className='second-container'>

    <div className="container">
      <DescMain mainHead={mainHead} para={para} img={devops} title={title} />
      <DescSecondList listObj={listObj} />



    </div>


  </div>
)
}

export default Devops

const title = 'DEVOPS';

const mainHead = 'DEVOPS is not a goal but a never ending process of continual improvement. '
const para = "Many of us heard of Devops, but ever did you think why is devops needed? what is it? Is it really necesseary for devops in todays world? DEVOPS came forth due to lack of cultural balance and communication between software Development and IT Operations Teams In simple words DEVOPS is a culture where people regardless of title or background, work together to imagine,DEVelop,deploy and OPerate a System.If you want to build better software faster, DevOps is the answer.  It combines development and operations to increase the efficiency, speed, and security of software development and delivery.A dexterous software stem advantage for business and their development.DevOps can be best explained as people working together to conceive, build and deliver secure software at top speed. ANR CONSULTING offers devops services through our experts which enable software development  and operations  teams to accelerate delivery through automation, collaboration, fast feedback, and iterative improvement. We analyze your current software development cycle,  IT resources, and  infrastructure, note down business expectations, identify IT capabilities and restrictions then implementation strategy is developed."
const listObj = {
title: 'THE DEVOPS SERVICES  ANR CONSULTING INCORPATES ARE:-',
shortDesc: '',
points: [
  'Containerization tools',
  'Infrastructure automation tools',
  'CI/CD tools',
  'Test automation tools',
  'Monitoring tools',
  'Coding & scripting',
  'Databases / data storages',
  'Clouds',
  


]
}