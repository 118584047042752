import {Routes,Route,useMatch } from 'react-router-dom'
import './App.css';
import NavBar from './components/NavBar/NavBar';

import Home from './pages/Home'
import Services from './pages/Services'
import AboutUs from './pages/AboutUs';
import Training from './pages/Training';
import Careers from './pages/Careers';
import ContactUs from './pages/ContactUs';
import Footer from './components/Footer/Footer';
import CalltoAction from './components/CalltoAction/CalltoAction';


import Java from './morepages/Java';
import Sap from './morepages/Sap';
import Salesforce from './morepages/Salesforce';
import Dotnet from './morepages/Dotnet';
import Bigdata from './morepages/Bigdata';
import Ba from './morepages/Ba';
import Devops from './morepages/Devops';
import Servicetesting from './morepages/Servicetesting';
import Qa from './morepages/Qa';
import Dt from './morepages/Dt';







function App() {
  const match = useMatch('/contact');
  return (
    <>
      <NavBar />
      
      <Routes>
      <Route path='/' element={<Home/>} />
   
      <Route path='services' element={<Services/>} />
      <Route path='about' element={<AboutUs/>} />
      <Route path='training' element={<Training/>} />
      <Route path='careers' element={<Careers/>} />
      <Route path='contact' element={<ContactUs/>} /> 

      <Route path='services/java' element={<Java/>} /> 
      <Route path='services/sap' element={<Sap/>} /> 
      <Route path='services/salesforce' element={<Salesforce/>} /> 
      <Route path='services/dotnet' element={<Dotnet/>} /> 
      <Route path='services/bigdata' element={<Bigdata/>} /> 
      <Route path='services/devops' element={<Devops/>} /> 
      <Route path='services/servicetesting' element={<Servicetesting/>} /> 
      <Route path='services/qa' element={<Qa/>} />
      <Route path='services/ba' element={<Ba/>} /> 
      <Route path='services/dt' element={<Dt/>} /> 


     
    </Routes>

      
    

      {/* <Test/> */}

     {!match && <CalltoAction/>} 

       <Footer/>

    </>
  );
}

export default App;
