import React from 'react'
import {Link} from 'react-router-dom'
import './header.css'
import hero from './hero.jpg'
function Header() {
  return (
    <>
    <header className='header'>

        <div className="header-content">


            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                      <div className="text-container">
                        <h1 className='color-primary fw600 mb-1'>Among America's</h1>
                        <h2 className='text-medium mb-3'> 
                             Most Promising Companies
                             </h2>

                        <h4 className='mb-5'>ANR - We Show You The Right Direction</h4>
                         
                      <Link to={'contact'}>
                      <button className='btn btn-primary btn-main'>Contact Us</button>
                      </Link>   
                     
                      </div>
                    </div>

                    <div className="col-lg-6">
                       <img className='img-fluid' src={hero} alt="" />
                        </div>
                </div>
            </div>
        </div>

    </header>
    </>
  )
}

export default Header