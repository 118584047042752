import React from 'react'
import DescMain from '../components/DescCards/DescMain'
import DescSecondList from '../components/DescCards/DescSecondList'
import dotnet from '../images/training/dotnet.png'
function Dotnet() {

  return (
    <div className='second-container'>

               <div className="container">
               <DescMain mainHead={mainHead} img={dotnet} title={title}/>
                <DescSecondList listObj={listObj}/>
                <DescSecondList listObj={listObj2}/>
                <DescSecondList listObj={listObj2}/>

               </div>
        
         
    </div>
  )
}

export default Dotnet

const title='.NET';

const mainHead='ANR Consulting offers your company instant depth to its .NET and other Microsoft initiatives. We provide professional design, development, integration and training services by experienced Microsoft domain experts for Microsoft .NET (including ASP.NET), SQL Server, BizTalk, SharePoint, Dynamics and more.'

const listObj={
  title:'.NET ARCHITECTURE CONSULTING :',
  shortDesc:'Our expert architects can provide a series of architecture-related services to ensure a successful solution design. Intertech’s team can:',
  points:[
    'Architect applications, services, and components using field proven .NET best practices',

    'Create your development environment architecture and staging environment from test to production',
    
    'Define an application blueprint that maximizes scalability, availability, and manageability and separates presentation, business, and data layers',
    
    'Identify security policies for authentication and authorization',
    
    'Create strategies backed by an architecture for exception management including runtime monitoring and configuration',
    
    'Define a plan for deployment including physical environment architecture, location of application components, and distribution boundaries',
    
    'Troubleshoot performance issues due to inefficient architecture',
    
    
    
  ]
}

const listObj2={
  title:'.NET DESIGN CONSULTING :',
  shortDesc:'Our expert application development consultants provide full .NET architecture, design, and development services. In .NET design, our team can',
  points:[
    'Plan and layout frameworks and class hierarchies using best practices for clarity, extensibility, and security',

    'Create your development environment architecture and staging environment from test to production',
    
    'Define an application blueprint that maximizes scalability, availability, and manageability and separates presentation, business, and data layers',
    
    'Define guidelines for exception handling including error message design, handling, and wrapping Outline auditing standards',
    
    'Create strategies backed by an architecture for exception management including runtime monitoring and configuration',
    
    'Create guidelines for partitioning an application/service into assemblies and packaging for distribution',
    
    'Share coding guidelines and implement tools like FxCop to ensure consistent implementation of design from naming to exception handling'
    
    
    
    
  ]
}

const listObj3={
  title:'.NET PATTERNS CONSULTING ',
  shortDesc:'Our expert .NET consultants can help you leverage existing design patterns as well as identify patterns that exist within your custom solution. Our experts can help you:',
  points:[
    'Assess your architecture and design and select patterns which fit your solution including one or more of the main .NET patterns to include the Intercepting Filter Pattern, Page Controller Pattern, Template Method Pattern, Observer Pattern, Iterator Pattern, Decorator Pattern, Adapter Pattern, Factory Pattern, Strategy Pattern, Composite Pattern',
    'Identify custom patterns and create supporting classes, frameworks, and code for implementation',
    'Outline deployment patterns',
 
  ]
}