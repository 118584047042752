import React from 'react'
import logo from './logo.png'
import { Link, NavLink } from 'react-router-dom'

import './navbar.css'
function NavBar() {

    let activeClassName = "active";

    var serviceTab = [{
        name: '.NET Consulting',
        route: 'dotnet'
    },
    {
        name: 'Java',
        route: 'java'
    },
    {
        name: 'SAP CONSULTING',
        route: 'sap'
    },


    ]
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-dark navbar-custom fixed-top top-nav-collapse">
             
                {/* <a className="navbar-brand logo-text page-scroll" href="index.html">ANR Consulting</a> */}

                {/* <!-- Image Logo --> */}

                <Link to={'/'} className="navbar-brand logo-image">
                <img src={logo} alt="alternative"/>
                </Link>
               

                {/* <!-- Mobile Menu Toggle Button --> */}
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-awesome fas fa-bars "></span>
                    <span className="navbar-toggler-awesome fas fa-times"></span>
                </button>
                {/* <!-- end of mobile menu toggle button --> */}

                <div className="navbar-collapse collapse " id="navbarsExampleDefault">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">

                            <NavLink
                                className={({ isActive }) =>
                                    isActive ? `nav-link ${activeClassName}` : "nav-link"
                                }
                                to="/"
                            >
                                Home
                            </NavLink>

                            {/* <a className="nav-link page-scroll active" href="#header">Home <span className="sr-only">(current)</span></a> */}
                        </li>
                        <li className="nav-item">
                            <NavLink
                                className={({ isActive }) =>
                                    isActive ? `nav-link ${activeClassName}` : "nav-link"
                                }
                                to="about"
                            >
                                About
                            </NavLink>

                        </li>


{/*                         
                        <li className="nav-item dropdown">
                            <NavLink
                                id="navbarDropdown" role="button" aria-haspopup="true" aria-expanded="false"
                                className={({ isActive }) =>
                                    isActive ? `nav-link dropdown-toggle ${activeClassName}` : "nav-link dropdown-toggle"
                                }
                                to="services"
                            >
                                Services
                            </NavLink>
                           
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">

                                {serviceTab.map((x, i) =>

                                <>      
                                    <Link key={i} className="dropdown-item" to={`services/${x.route}`}>
                                        <span className="item-text">{x.name}</span>
                                    </Link>
                                    <div className="dropdown-items-divide-hr"></div>
                                     </>

                                )}
                            </div>
                        </li>
                        

                        <li className="nav-item dropdown">
                            <NavLink
                                id="navbarDropdown" role="button" aria-haspopup="true" aria-expanded="false"
                                className={({ isActive }) =>
                                    isActive ? `nav-link dropdown-toggle ${activeClassName}` : "nav-link dropdown-toggle"
                                }
                                to="training"
                            >
                                Training
                            </NavLink>
                           
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">

                                {serviceTab.map((x, i) =>

                                <>      
                                    <Link key={i} className="dropdown-item" to={`services/${x.route}`}>
                                        <span className="item-text">{x.name}</span>
                                    </Link>
                                    <div className="dropdown-items-divide-hr"></div>
                                     </>

                                )}
                            </div>
                        </li> */}
                        
                        <li className="nav-item">
                            <NavLink
                                className={({ isActive }) =>
                                    isActive ? `nav-link ${activeClassName}` : "nav-link"
                                }
                                to="services"
                            >
                                Services
                            </NavLink>

                        </li>


                        <li className="nav-item">
                            <NavLink
                                className={({ isActive }) =>
                                    isActive ? `nav-link ${activeClassName}` : "nav-link"
                                }
                                to="training"
                            >
                                Training
                            </NavLink>

                        </li>
                        
                        <li className="nav-item">
                            <NavLink
                                className={({ isActive }) =>
                                    isActive ? `nav-link ${activeClassName}` : "nav-link"
                                }
                                to="contact"
                            >
                                Contact
                            </NavLink>

                        </li>
        
                    </ul>
             
                </div>
            </nav>
        </>
    )
}

export default NavBar