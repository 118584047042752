import React from 'react'
import DescMain from '../components/DescCards/DescMain'
import DescSecondList from '../components/DescCards/DescSecondList'
import bigdataa from '../images/training/big-data.png'
function bigdata() {
  return (
  <div className='second-container'>

    <div className="container">
      <DescMain mainHead={mainHead} para={para} img={bigdataa} title={title} />
      <DescSecondList listObj={listObj} />



    </div>


  </div>
)
}

export default bigdata

const title = 'BIG DATA';

const mainHead = '"NO DATA IS CLEAN, BUT MOST IS USEFUL".'
const para = "Everyone has an inlking about the importance of data in todays era. Data is the new raw material of organizations . But did you know there is another form of data? what is it and why is it important? Big data is a massive amount of data produced by  transaction processing systems, customer databases, documents, emails, medical records, internet clickstream logs, mobile apps and social networks.Big Data helps to uncover  trends, patterns, and correlations in large amounts of raw data to help make data-informed decisions and generate valuable insights. ANR CONSULTANTS provides Big Data program through our experienced professional in this precise domain to assist them accomplish maximum returns and increase efficiency in operations. We help the organizations comprehend what their customer wants and who are their best customers because the competitiveness of company lies in identifying their customer and information about them. With the assistance of our services and tools and technologies we help you get customized solutions by generation insights from your bigdata."
const listObj = {
title: 'THE BIG DATA SERVICES INCORPORATED ARE:-',
shortDesc: '',
points: [
  'Data generation and Data collection ',
  'Data processing ',
  'Data storage',
  'Data Analytics & Visualization',
  'Data Validation Framework',
  'Data Quality Management Process',


]
}