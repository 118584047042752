import React from 'react'
import {Link} from 'react-router-dom'
import java from '../images/training/java.png'
import sap from '../images/training/sap.png'
import salesforce from '../images/training/salesforce.png'
import dotnet from '../images/training/dotnet.png'
import bigdata from '../images/training/big-data.png'
import ba from '../images/training/ba.png'
import devops from '../images/training/devops.png'
import st from '../images/training/st.png'
import qa from '../images/training/qa.png'
import dm from '../images/training/dm.png'
function Services() {
  return (
    <div className='second-container'>


      <div className="container">

        <div className="text-center pb-5">
          <h2 className='color-primary'>OUR SERVICES</h2>
        </div>
        <div className="col-lg-12">
          <div className="row mb-5">

            {serviceData.map((x, i) => {

                
             return( <div key={i} className="col-lg-4">
                <div className="card p-3 mb-3">

                  <img src={x.img} className='img-fluid mx-auto d-block mb-3' alt="" />
                  <h4>{x.name}</h4>
                  <p>{x.desc}</p>
                 <Link to={x.route}> 
                 <button className='btn btn-primary btn-second'>Know More</button>
                 </Link>
                </div>
              </div>)

            })}


          </div>

        </div>

      </div>


    </div>
  )
}

export default Services




export const serviceData = [

  {
    name: 'Java',
    img: java,
    route:'java',
    desc: 'ANR CONSULTING will assist and guide you to learn, build and implement the best and updated version of java technology that will get you excellent results for your desired project.'
  },

  {
    name: 'SAP',
    img: sap,
    route:'sap',
    desc: 'ANR consultants has the skillset and experience to assist with all aspects of your SAP application lifecycle.'
  },
  {
    name: '.NET CONSULTING',
    img: dotnet,
    route:'dotnet',
    desc: 'ANR has expert consultants who are skilled in .NET frameowrkand offer you services which will help you implement .NET applications to the client satisfaction.'
  },
  {
    name: 'Business Analyst',
    img: ba,
    route:'ba',
    desc: 'ANR CONSULTING provides customized services and help the client to analyze and understand obstacles in business and realize opportunities.'
  },
  {
    name: 'Quality Analyst',
    img: qa,
    route:'qa',
    desc: 'ANR consultants provide the client with services which evaluate the products or systems, software to ensure they are free of defects and optimize theri quality drive.'
  },
  {
    name: 'Sales Force',
    img: salesforce,
    route:'salesforce',
    desc: 'ANR salesforce experts will help you how to configure salesforce to collect, analyze and retrive vital customer information.'
  },

  {
    name: 'Big Data',
    img: bigdata,
    route:'bigdata',
    desc: 'ANR CONSULTING offers you a wide range of services to help you acquire skills on implementation on bigdata applications.'
  },
 
  {
    name: 'DevOps',
    img: devops,
    route:'devops',
    desc: 'ANR provides you with expert help who are specialized in DEVOPS field to assist you to deliver high qualtiy software faster.'
  },
  {
    name: 'Service Testing',
    img: st,
    route:'servicetesting',
    desc: 'ANR CONSULTING provides you eith expert help and guidence and skills to perform the testing functionality and guide testing process which helps you become an expert in the same.'
  }
  ,
 
  {
    name: 'Data Technologies',
    img: dm,
    route:'dt',
    desc: 'ANR CONSULTING provides the client with data technology to build solutions for data management and discover new business or analytical insights from the data.'
  }
]