import React from 'react'
import DescMain from '../components/DescCards/DescMain'
import DescSecondList from '../components/DescCards/DescSecondList'
import salesforcee from '../images/training/salesforce.png'
function salesforce() {
  return (
  <div className='second-container'>

    <div className="container">
      <DescMain mainHead={mainHead} para={para} img={salesforcee} title={title} />
      <DescSecondList listObj={listObj} />



    </div>


  </div>
)
}

export default salesforce

const title = 'SALESFORCE';

const mainHead = '"NOTHING IS MORE IMPORTANT THAN MAKING SURE EVERY CUSTOMER IS SUCCESSFUL IN OUR SERVICE"'
const para = "How are the organizations successful in keeping track of customer information and using it to their organiztions growth? The answer is salesforce.Salesforce is the world's number one  customer relationship management (CRM) platform, which helps in  marketing, sales, commerce, service and IT teams work as one from anywhere.Salesforce is an excellent way to collect customer information, in terms of both quantity and quality. Every minute detail that is of importance is collected and stored by the software and can be accessed easily. This accumulated data, all in one place, helps you to network well as you can target leads effortlessly.The most prominent companies that use Salesforce are Spotify,Amazon Web Services,U.S. Bank, Toyota,Macy's,T-Mobile,Aldo,The New York Post,American Express and many more."
const listObj = {
title: 'THE SALESFORCE SERVICES AT ANR CONSULTING INCORPORATES:-',
shortDesc: '',
points: [
  'Sales Cloud-Generic Business Process',
  'Service Cloud and Customer Service',
  'Salesforce-Force.Com Platform',
  'Manage Users, Relationships in Salesforce',
  'Object Level Security Model and Field Level Security Model',
  'Record Level Security Model-Sharing Settings',
  'Workflows and Approvals',
  'Data Management With SFDC',
  'Process Builder and Community Creation',
  'Security Settings, Email administration , Administrative Integration',
  'Reports and Dashboards',
  'Resolving Project Issues',
  'Salesforce Development(complete)',
  'Programmatic Integration',
  'Integration and WebServices',
  'SALESFORCE LIGHTNING',
  'LIGHTNING AURA COMPONENTS AND APPLICATIONS',
  'LIGHTNING WEB COMPONENTS',


]
}