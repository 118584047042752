import React, { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { FiMail } from 'react-icons/fi'
import phone from '../images/contact/phone.png'
import office from '../images/contact/office.png'
import mail from '../images/contact/mail.png'
import time from '../images/contact/time.png'

function ContactUs() {

  const [mailInfo, setMailInfo] = useState({
    name: "",
    email: "",
    subject: "",
    message: ""

  });

  //  const URL = 'https://anrconsultingllc.com'
  // const URL = 'http://localhost'

  const toastObj = {
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  }



  const handleChange = (event) => {
    setMailInfo({ ...mailInfo, [event.target.name]: event.target.value });
  };


  const handleSubmit = (event) => {

    event.preventDefault();

    if (mailInfo.email == '') {
      toast.error("Email Required", toastObj);
    } else if (mailInfo.message == '') {
      toast.error("Message Required", toastObj);
    } else {

      axios.post(`https://anrconsultingllc.com/API/mail.php`, mailInfo).then((result) => {

    
        const resCode = result.data.code

        if (resCode == 200) {

          toast.success("Thank you, Mail Sent", toastObj);

        } else {
          toast.error("Server Error", toastObj);


        }

      })

    }
 



  };

  const clear = (event) => {
    event.preventDefault();
    setMailInfo({ name: "", email: "", subject: "", message: "" });
  };


  return (
    <div className='second-container'>


      <div className="container pb-5">



        <div className="row">

          <div className="col-lg-6">

            <div className="lc-block pb-3">
              <div editable="rich">
                <h2>Contact Us</h2>

                <div className="d-flex justify-space-between align-items-center">
                  <img src={phone} className='p-2' />
                  <p className='mt-2 p-2'>+ 15712860947.</p>
                </div>
                <div className="d-flex justify-space-between align-items-center">
                  <img src={mail} className='p-2' />
                  <p className='mt-2 p-2'>info@anrconsultingllc.com</p>
                </div>
                <div className="d-flex justify-space-between align-items-center">
                  <img src={office} className='p-2' />
                  <p className='mt-2 p-2'>1603 CLOVER CT, MISSOURI CITY,77459.</p>
                </div>
                <div className="d-flex justify-space-between align-items-center">
                  <img src={time} className='p-2' />
                  <p className='mt-2 p-2'>Mon-Sun <br />
                    9am to 9pm.
                  </p>
                </div>
              </div>
            </div>
          </div>


          <div className="col-lg-6">



            <div className="card pt-2 contactCard">

              <div className="card-header bg-w">

                <h5> <span> <FiMail className='icon-font-basic' /></span> Send us a mail</h5>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="form-group mb-4">
                    <label>Your Name (optional)</label>

                    <input name="name" type="text" onChange={handleChange} className="form-control" placeholder="" value={mailInfo.name} />
                  </div>

                  <div className="form-group mb-4">
                    <label>Your Email Address</label>
                    <input name="email" type="email" onChange={handleChange} className="form-control" placeholder="name@example.com" value={mailInfo.email} />
                  </div>

                  <div className="form-group mb-4">
                    <label>Subject (optional)</label>
                    <input name="subject" type="text" onChange={handleChange} className="form-control" placeholder="" value={mailInfo.subject} />
                  </div>

                  <div className="form-group mb-4">
                    <label>Your Message</label>
                    <textarea name="message" value={mailInfo.message} onChange={handleChange} className="form-control" rows="3" maxLength="300" autoComplete="off" spellCheck="false" ></textarea>
                  </div>

                  <button type="submit" className="btn btn-primary btn-main">Send</button>

                  <button type="button" onClick={clear} className="btn btn-primary btn-main-danger m-2">Clear</button>



                </form>

              </div>
            </div>
          </div>


        </div>
      </div>


      <ToastContainer />

    </div>
  )
}

export default ContactUs