import React from 'react'
import { ImArrowRight } from 'react-icons/im'
import why from './why.jpg'
function WhySection() {
    return (
        <>
       
            <div className="row">
                

                
                <div className="col-lg-6">
                    <div className="text-start mt-5">

                        <h2 className='mb-3'>Why <span className='color-primary'>ANR Consulting </span> ..?</h2>
                        <div className="d-flex align-items-start justify-space-between">
                            <div className="pt-3 mt-2">
                                <ImArrowRight className='rigthArrow' />
                            </div>
                            <div className="point p-2">
                                <div className='mt-3 ml-3'>
                                    <h5>We guarantee 100% customer satisfaction</h5>
                                    <p> ANR believes </p>
                                    <p className='text-large-second color-primary'> 
                                    “ It takes months to find a customer …seconds to loose one”.</p>
                                    
                                     <p> So we strive for 100% customer satisfaction with every client.</p>
                                    </div>
                            </div>
                        </div>

                        <div className="d-flex align-items-start justify-space-between">
                            <div className="pt-3 mt-2">
                                <ImArrowRight className='rigthArrow' />
                            </div>
                            <div className="point p-2">
                                <div className='mt-3 ml-3'>
                                    <h5>We deliver value quickly</h5>
                                    <p>Our highest priority is to satisfy customers through early & quick delivery</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="col-lg-6">

                    
                 
                    <img src={why} alt="" className='img-fluid mx-auto d-block' />
                      
                
                </div>

                </div>

            
            
        </>
    )
}

export default WhySection