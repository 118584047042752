import React from 'react'
import custom from '../../images/settings.png'
import req from '../../images/document.png'

import design from '../../images/responsive.png'
import coding from '../../images/coding.png'
import testing from '../../images/testing.png'
function Whatwedo() {
  return (
    <>
	<div className="container mt-5">

	
     <div className="row">
         <div className="col-lg-12">
           <div className="small-cont text-center">
            <h2 className='mb-5'>What We Do</h2>
       
          </div>
                </div>
            </div>
    
            <div className="row">
                <div className="col-lg-12">
                    
                <div className="container">
	
	<div className="row">
		<div className="col-md-6 py-4">
			<div className="lc-block d-flex">
				<div className="me-3">
					<img src={custom} alt="" />
				</div>
				<div className="px-4">
                <div editable="rich">
						<h5>Custom Software Development</h5>
			<p>ANR Consulting can support the complete development of your business solution – from initial design and testing, to training and final deployment
				 
				 </p>
					</div>
				</div>
			</div>
		</div>
		<div className="col-md-6 py-4">
			<div className="lc-block d-flex">
				<div className="me-3">
                <img src={req} alt="" />
				</div>
				<div className="px-4">
                <div editable="rich">
						<h5>Requirments</h5>
						<p>ANR Consulting expert consultants analyse clients requirements and &nbsp;plan out and design the structure of the software.</p>					</div>
				</div>
			</div>
		</div>
	</div>
	<div className="row">
		<div className="col-md-6 py-4">
			<div className="lc-block d-flex">
				<div className="me-3">
                <img src={design} alt="" />
				</div>
				<div className="px-4">
                <div editable="rich">
						<h5>Design</h5>
						<p>Design the robust, scalable, secure and easy to use software keeping the client satisfaction the utmost priority.&nbsp;</p>					</div>
				</div>
			</div>
		</div>
		<div className="col-md-6 py-4">
			<div className="lc-block d-flex">
				<div className="me-3">
                <img src={coding} alt="" />
				</div>
				<div className="px-4">
				<div editable="rich">
						<h5>Development</h5>
						<p>ANR Consulting development team analyse and understand your business objective, define the requirements and write details specification, then we develop, test and implement your software for you.</p>					</div>
				</div>
			</div>
		</div>
	</div>
	<div className="row">
		<div className="col-md-6 py-4">
			<div className="lc-block d-flex">
				<div className="me-3">
                <img src={testing} alt="" />
				</div>
				<div className="px-4">
                <div editable="rich">
						<h5>Testing</h5>
						<p> At ANR Consulting,every product we develop undergoes rigorous product testing to ensure the in-time delivery of flawless software.We make sure that we exceed the clients expectations.</p>					</div>
				</div>
			</div>
		</div>
		
	</div>
</div>


                    </div>
                    
                    
                    </div>
					</div>
    </>
  )
}

export default Whatwedo