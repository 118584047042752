import React from 'react'
import Cardscarousel from '../components/CardCaro/Cardscarousel'
import Header from '../components/Header/Header'
import Whatwedo from '../components/Whatwedo/Whatwedo'
import WhySection from '../components/WhySection/WhySection'

function Home() {
  return (
    <>
    <Header/>
     <Cardscarousel/>
     <Whatwedo/>
     <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-lg-12">
            <WhySection/>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home