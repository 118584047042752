import React from 'react'
import {Link} from 'react-router-dom'
function CalltoAction() {
  return (
    <>
    
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                <div className="card mb-5 p-2">
                    <div className="card-body">
                    <h4>Need expertise and objective eye to help guide a business?</h4>
                <p>  
                ANR Consulting provide expertise and customized solution to your problem across the globe.

                <br />
                Our highly experienced and professional team enables us to provide services to the clients accordingly which are highly confidential and secured.

                
                </p>

                <div className="text-end">
                   <Link to={'contact'}>
                   <button className='btn btn-primary btn-main'> Contact Us</button>
                   </Link> 
                </div>
                    </div>
                </div>
               
            </div>
        </div>
    </div>
    </>
  )
}

export default CalltoAction