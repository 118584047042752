import React from 'react'
import { ImArrowRight } from 'react-icons/im'
import Whatwedo from '../components/Whatwedo/Whatwedo'

function AboutUs() {


  return (
    <div className='second-container'>

      <div className="container">

        <div className="about-main">

        
        <h2 className='pb-2 text-center'><span className='color-primary'>“ </span> 
        Find the best solution with us <span className='color-primary'>”</span></h2>
        <div className="text-start">
          <div className="d-flex align-items-start justify-space-between">
            <div className="pt-3 mt-2">
              <ImArrowRight className='rigthArrow color-primary' />
            </div>
            <div className="point p-2">
              <div className='mt-3 ml-3'>
                <p>ANR Consulting helps you to guide a business with consultants specializing in various industries and areas such as management,
                  information technologies, sales and marketing. </p>
              </div>
            </div>
          </div>

          <div className="d-flex align-items-start justify-space-between">
            <div className="pt-3 mt-2">
              <ImArrowRight className='rigthArrow color-primary' />
            </div>
            <div className="point p-2">
              <div className='mt-3 ml-3'>
                <p> We help you with our expertise skills and deeper knowledge of business trends, industry challenges and new technologies with no hidden or extra charges.
                </p>
              </div>
            </div>
          </div>

          <div className="d-flex align-items-start justify-space-between">
            <div className="pt-3 mt-2">
              <ImArrowRight className='rigthArrow color-primary' />
            </div>
            <div className="point p-2">
              <div className='mt-3 ml-3'>
                <p> We value and learn about each client’s business and goals and tailor advice and strategy consulting to the specific challenges.
                </p>
              </div>
            </div>
          </div>


        </div>
        </div>

      </div>

   <Whatwedo/>

    </div>
  )
}

export default AboutUs