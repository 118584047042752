import React from 'react'
import DescMain from '../components/DescCards/DescMain'
import DescSecondList from '../components/DescCards/DescSecondList'
import sap from '../images/training/sap.png'
function Sap() {

  return (
    <div className='second-container'>

               <div className="container">
               <DescMain mainHead={mainHead} img={sap} title={title}/>
                <DescSecondList listObj={listObj}/>
               </div>
        
         
    </div>
  )
}

export default Sap

const title='SAP';

const mainHead='ANR Consulting provides Strategy, Global consulting and advisory services; ranging from project implementation, production support and application management of the SAP platform. ANR Consulting has exceptional experience in blending operations and analytics, giving a complete picture of business across heterogeneous systems and directly aligned with SAP’s vision of leveraging high performance real time in-memory computing. ANR Consulting specializes in providing HANA Hybrid solutions combining operations (ECC), historical analytics (BW) and planning (EPM).'

const listObj={
  title:'ANR Consulting SAP Consulting Services Includes:',
  shortDesc:'',
  points:[
    'SAP Functional and Technical Consulting for all modules',
    'Full Cycle Global SAP Roll-Out',
    'SAP Application Management and Development (FRICE – Forms, Reports, Interfaces, Conversions and Enhancements)',
    'SAP Enhancements and Customizations',
    'SAP Application and System Integration',
    'SAP Upgrades and Migrations',
    'SAP Industry Solutions',
    'Complete SAP Support',
    'Enterprise Services Architecture and Integration Architecture'

  ]
}