import React, { Component } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import './caro.css'

import java from '../../images/training/java.png'
import sap from '../../images/training/sap.png'
import salesforce from '../../images/training/salesforce.png'
import dotnet from '../../images/training/dotnet.png'
import bigdata from '../../images/training/big-data.png'
import ba from '../../images/training/ba.png'
import devops from '../../images/training/devops.png'
import st from '../../images/training/st.png'
import qa from '../../images/training/qa.png'
import dm from '../../images/training/dm.png'





export default function Cardscarousel() {

    const settings = {
        dots: true,
        infinite: true,
        initialSlide: 1,
        speed: 600,
        slidesToShow: 3,
        slidesToScroll: 4,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 2,
                    speed: 800,
                    autoplaySpeed: 5000,
                }
            }
        ]

    }

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="small-cont text-center">
                            <h2 className='mb-5'>Exclusive Training Offered In</h2>
                            
                        </div>
                    </div>
                </div>
                <div className="row">

                    <div className="col-lg-12">
                        <div className="custom-caro text-center">
                            <div className=' mb-5 ml-auto mr-auto'>
                                <div className="services">
                                    <Slider {...settings}>


                                        {CaroData.map((x, i) => {


                                            return (


                                                <div>
                                                    <div className="card caro-card">

                                                        <div className="card-body text-center">

                                                            <img src={x.img} className='img-fluid mx-auto d-block mb-5' alt="" />
                                                            <h4>{x.name}</h4>


                                                        </div>
                                                    </div>

                                                </div>
                                            )
                                        })}





                                    </Slider>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>









        </>

    );
}





export const CaroData = [

    {
        name: 'Java',
        img: java,
        desc: 'ANR consultants helps you to build and implement the Java technology that will bring the best results for your specific goals.'
    },
    {
        name: 'SAP',
        img: sap,
        desc: 'ANR consultants has the skillset and experience to assist with all aspects of your SAP application lifecycle.'
    },
    {
        name: 'Sales Force',
        img: salesforce,
        desc: 'ANR consultants delivers quality solutions quickly by starting with proven assets from thousand’s of past projects.'
    },
    {
        name: '.NET',
        img: dotnet,
        desc: 'ANR consultants are experts in design and implementation of .NET applications adding value to the products and clients.'
    },
    {
        name: 'Big Data',
        img: bigdata,
        desc: 'ANR consultants are experts in design and implementation of Big Data applications adding value to the products and clients.'
    },
    {
        name: 'Business Analyst',
        img: ba,
        desc: 'ANR consultants are experts in providing Business Analyst services for applications adding value to the products and clients.'
    },
    {
        name: 'DevOps',
        img: devops,
        desc: 'ANR consultants are experts in Devops services  by adding value to the products and clients.'
    },
    {
        name: 'Service Testing',
        img: st,
        desc: 'ANR consultants are experts in Service Testing  by adding value to the products and clients.'
    }
    ,
    {
        name: 'Quality Analyst',
        img: qa,
        desc: 'ANR consultants are experts in Quality Analyst  by adding value to the products and clients.'
    },
    {
        name: 'Data Technologies',
        img: dm,
        desc: 'ANR consultants Data warehousing consulting Services help clients to quickly obtain insights and support decision making.'
    }]