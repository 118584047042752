import React from 'react'
import DescMain from '../components/DescCards/DescMain'
import DescSecondList from '../components/DescCards/DescSecondList'
import javaimg from '../images/training/java.png'
function java() {

  return (
    <div className='second-container'>

      <div className="container">
        <DescMain mainHead={mainHead} para={para} img={javaimg} title={title} />
        <DescSecondList listObj={listObj} />
      </div>


    </div>
  )
}

export default java

const title = 'Java';

const mainHead = 'Do you know in todays IT world java is the most in demand and most sought-after language. The majority of organizations today rely on java for their software development.The reason behind this is because of its open source (no need to buy a licence)functionality and of its independence and portability, as a single written Java program can run into a variety of platforms (Windows, Unix, Linux, Apple and others).Java is used in numerous fields such as financial, e-commerce, enterprise, mobile, distributed, or big data applications.Being a Java developer, one should have the knowledge about the Java-based tools and technologies used by the most of the IT industries. Here comes ANR consulting comes into play, we have Consultants who are professionals, have rich experience in their field and a deep understanding of the industry they are in. Based on their expertise, they provide expert opinion, analysis and recommendations to a client and help them improve their business performance.'
const para = 'At ANR CONSULTING we provide the resources, skills and knowledge that is required to gain a foothold in the industries and also provide solutions to the client to improve their efficiency and performance level. '
const listObj = {
  title: 'The JAVA services at ANR CONSULTING incorporate:-',
  shortDesc: '',
  points: [
    'Provides with the history and evolution of the java language',
    'Provides with the basic to advance java knowledge',
'Designing, implementing, and maintaining Java applications',
'Java applications Installations and Updations(all latest/updated versions)',
'How to optimize java code performance',
'Analysis, programming, testing, and debugging of software',
'Web application development',
'Java testing tools and many more',
'Provide mentoring and training required',
'Proficiency in handling the projects.',

  ]
}
